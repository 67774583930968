import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div>
            <h1>Solution Mania</h1>
            <p>@all right reserved</p>
        </div>
        <div>
            <h5>Follow us</h5>
            <div>
                <a href="https://youtube.com" target={"blank"}>YouTube</a>
                <a href="https://instagram.com" target={"blank"}>Instagram</a>
                <a href="https://Github.com" target={"blank"}>Github</a>

            </div>
        </div>
    </footer>
  )
}

export default Footer